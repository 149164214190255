<template>
	<div class="container">
		<div class="model" v-if="confirmModal" @click.stop="cancelConfirmModal()"></div>
		<div class="confirm-wrap flex-column align-center" v-if="confirmModal">
			<div class="confirm-title">现有卡中的积分总计</div>
			<div class="confirm-content">
				{{confirmPoints}}积分
			</div>
			<div class="confirm-tips">*售卖中的卡积分不包含在内*</div>
			<div class="confirm-tips2">是否确认一键全部提取？</div>
			<div class="btn-wrap flex-row align-center">
				<div class="btn-left" @click.stop="confirmTx()">确认</div>
				<div class="btn-right" @click.stop="cancelConfirm()">取消</div>
			</div>
		</div>
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">消费积分</div>
			<div class="zd-wrap flex-row align-center justify-center" @click.stop="navLog()">
				<img src="../../assets/points/icon-zd.png" class="icon-zd" alt="">
				<div class="zd-text">账单</div>
			</div>
		</div>
		<div class="top-wrap">
			<img @click.stop="doCk()" src="../../assets/points/icon-ck.png" class="icon-ck" alt="">
			<div class="my-points">{{points}}</div>
			<img src="../../assets/points/icon-extraction.png" alt="" class="extraction-btn"
				@click.stop="showConfirmModal()">
		</div>
		<div class="list-menu-wrap flex-row align-center">
			<div class="list-item list-item-sel flex-column align-center" @click.stop="changeTab(1)">
				<div class="list-item-ti">抵用券（{{tab1.total}}）</div>
				<div class="list-item-line" v-if="tab ==1"></div>
			</div>
			<div class="list-item list-item-sel flex-column align-center" @click.stop="changeTab(2)">
				<div class="list-item-ti">藏品（{{tab2.total}}）</div>
				<div class="list-item-line" v-if="tab ==2"></div>
			</div>
			<div class="list-item list-item-sel flex-column align-center" @click.stop="changeTab(3)">
				<div class="list-item-ti">实物（{{tab3.total}}）</div>
				<div class="list-item-line" v-if="tab ==3"></div>
			</div>
		</div>
		<div v-if="tab ==1">
			<van-list  v-model="tab1.loading" @load="selectPointBoxInfoList"
				:finished="tab1.finished" finished-text="没有更多了" :error.sync="tab1.error" error-text="请求失败，点击重新加载">
				<div class="box-item flex-row align-center space-between flex-wrap">
					<div v-for="(item,index) in  tab1.list" :key="index" class="box" @click.stop="navDetail(index)">
						<img :src="item.listViewPic" class="box-img" alt="">
						<div class="ti-con flex-row align-center space-between">
							<div class="box-ti">{{item.boxName}}</div>
							<div class="box-ps flex-row flex-end">
								<div>{{item.points}}</div>
								<div class="box-unit">
									积分
								</div>
							</div>
						</div>
						<div class="box-desc">{{item.boxTitle}}</div>
					</div>
				</div>
			</van-list>
		</div>

		<div v-if="tab==2">
			<van-list :immediate-check="immediateCheck" v-model="tab2.loading" @load="selectPointBoxInfoList2"
				:finished="tab2.finished" finished-text="没有更多了" :error.sync="tab2.error" error-text="请求失败，点击重新加载">
				<div class="box-item flex-row align-center space-between flex-wrap">
					<div v-for="(item,index) in  tab2.list" :key="index" class="box" @click.stop="navDetail(index)">
						<img :src="item.listViewPic" class="box-img" alt="">
						<div class="ti-con flex-row align-center space-between">
							<div class="box-ti">{{item.boxName}}</div>
							<div class="box-ps flex-row flex-end">
								<div>{{item.points}}</div>
								<div class="box-unit">
									积分
								</div>
							</div>
						</div>
						<div class="box-desc">{{item.boxTitle}}</div>
					</div>
				</div>
			</van-list>
		</div>
		<div v-if="tab ==3">
			<van-list :immediate-check="immediateCheck" v-model="tab3.loading" @load="selectPointBoxInfoList3"
				:finished="tab3.finished" finished-text="没有更多了" :error.sync="tab3.error" error-text="请求失败，点击重新加载">
				<div class="box-item flex-row align-center space-between flex-wrap">
					<div v-for="(item,index) in  tab3.list" :key="index" class="box" @click.stop="navDetail(index)">
						<img :src="item.listViewPic" class="box-img" alt="">
						<div class="ti-con flex-row align-center space-between">
							<div class="box-ti">{{item.boxName}}</div>
							<div class="box-ps flex-row flex-end">
								<div>{{item.points}}</div>
								<div class="box-unit">
									积分
								</div>
							</div>
						</div>
						<div class="box-desc">{{item.boxTitle}}</div>
					</div>
				</div>
			</van-list>
		</div>
		<div class="model" v-if="ckModal"></div>
		<div class="ck-modal" v-if="ckModal">
			<div class="other-login">
				<div class="other">
					<div class="text">我的积分</div>
				</div>
			</div>
			<div class="ck-my-points">{{points}}积分</div>
			<div class="flex-row align-center ck-i-wrap">
				<div class="ck-i-ti">充入的卡：</div>
				<input type="text" v-model="ckData.tokenId" placeholder="Token ID" class="ck-i-input">
			</div>
			<div class="flex-row align-center ck-i-wrap">
				<div class="ck-i-ti">充入积分：</div>
				<input type="number" v-model="ckData.points" placeholder="数量" class="ck-i-input">
			</div>
			<div class="dh-btn-wrap flex-row align-center space-between">
				<div class="dh-btn-w flex-row align-center justify-center" @click.stop="closeCkModal()">
					<div class="dh-btn-t">取消</div>
				</div>
				<div class="dh-btn-w flex-row align-center justify-center" @click.stop="transferPointToCard()">
					<div class="dh-btn-t">确认</div>
				</div>
			</div>
		</div>
		<div class="model" v-if="ckResModal"></div>
		<div class="ckres-modal" v-if="ckResModal">
			<div class="ckres-points">+{{ckData.points}}积分</div>
			<div class="ckres-rs-wrap flex-column align-center ">
				<div class="ckres-ti">账户余额</div>
				<div class="ckres-num">{{points}}积分</div>
			</div>
			<div class="ckres-btn flex-row align-center justify-center">
				<div class="ckres-btn-text" @click.stop="closeCkResModal()">确认</div>
			</div>
		</div>
		<div class="order-btn-wrap flex-column align-center" @click.stop="navOrder()">
			<div class="btn-text">查</div>
			<div class="btn-text">看</div>
			<div class="btn-text">订</div>
			<div class="btn-text">单</div>
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../../config/config.js';
	import util from '../../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				points: '',
				loading: false,
				finished: false,
				error: false,

				ckModal: 0,
				ckResModal: 0,
				ckData: {
					tokenId: '',
					points: ''
				},
				confirmModal: 0,
				confirmPoints: 0,
				tab: 1,
				immediateCheck: false,
				tab1: {
					pageNum: 1,
					pageSize: 10,
					total: '',
					list: [],
					loading: false
				},
				tab2: {
					pageNum: 1,
					pageSize: 10,
					total: '',
					list: [],
					loading: false
				},
				tab3: {
					pageNum: 1,
					pageSize: 10,
					total: '',
					list: [],
					loading: false
				}
			}
		},
		methods: {
			navOrder() {
				this.$router.push('/points/entryorder')
			},
			changeTab(tab) {
				if (tab != this.tab) {
					this.tab = tab;
				}
			},
			cancelConfirm() {
				this.confirmModal = 0;
			},
			confirmTx() {
				const that = this;
				if (that.confirmPoints <= 0) {
					Toast('没有可提取的积分');
					return;
				}
				Toast.loading({
					message: '提取中...',
					forbidClick: true,
					duration: 0,
				});
				that.axios.post(config.pointsRequest +
					`/point-api/rest/pointProductEntry/transferAllPointToUser`, {}, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						that.selectPointByUserId();
						Toast('提取成功');
						that.confirmModal = 0;
					} else {
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			showConfirmModal() {
				const that = this
				that.axios.post(config.pointsRequest +
					`/point-api/rest/pointProductEntry/selectTotalPoint`, {}, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						that.confirmPoints = response.data.data;
						that.confirmModal = 1;
					} else {
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			navLog() {
				this.$router.push('/points/log')
			},
			closeCkModal() {
				this.ckModal = 0
			},
			transferPointToCard() {
				const that = this;
				if (!that.ckData.tokenId) {
					Toast('请输入充入的卡Token ID')
					return;
				}
				if (!that.ckData.points) {
					Toast('请输入充入的积分数量')
					return;
				}
				Toast.loading({
					message: '',
					forbidClick: true,
					duration: 0,
				});
				let params = {
					tokenId: that.ckData.tokenId,
					points: that.ckData.points
				}
				that.axios.post(config.pointsRequest +
					`/point-api/rest/pointProductEntry/transferPointToCard`,
					params, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						console.log(response)
						that.getCkRes();

					} else {
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			navDetail(index) {
				let id;
				if (this.tab == 1) {
					id = this.tab1.list[index].boxId
				} else if (this.tab == 2) {
					id = this.tab2.list[index].boxId
				} else if (this.tab == 3) {
					id = this.tab3.list[index].boxId
				}
				this.$router.push('/points/boxdetail?id=' + id)
			},
			selectPointBoxInfoList() {
				const that = this
				// if (that.tab1.loading) return;
				// that.tab1.loading = true
				let params = new FormData()
				that.axios.post(config.pointsRequest +
					`/point-api/rest/pointBoxInfo/pageList?pageNum=${that.tab1.pageNum}&pageSize=${that.tab1.pageSize}`, {
						boxType: 1
					}, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						that.tab1.pageNum = that.tab1.pageNum + 1;
						let list = that.tab1.list;
						let newList = response.data.data.rows;
						console.log(newList)
						if (newList.length < that.tab1.pageSize) that.tab1.finished = true;
						list = list.concat(newList);
						that.tab1.list = list;
						that.tab1.total = response.data.data.total
					} else {
						that.tab1.error = true
						Toast(response.data.msg);
					}
					that.tab1.loading = false;
				}, response => {
					that.tab1.error = true;
					that.tab1.loading = false;
					Toast('获取失败，请稍后重试');
				})
			},
			selectPointBoxInfoList2() {
				const that = this
				let params = new FormData()
				that.axios.post(config.pointsRequest +
					`/point-api/rest/pointBoxInfo/pageList?pageNum=${that.tab2.pageNum}&pageSize=${that.tab2.pageSize}`, {
						boxType: 2
					}, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						that.tab2.pageNum = that.tab2.pageNum + 1;
						let list = that.tab2.list;
						let newList = response.data.data.rows;
						console.log(newList)
						if (newList.length < that.tab2.pageSize) that.tab2.finished = true;
						list = list.concat(newList);
						that.tab2.list = list;
						that.tab2.total = response.data.data.total;
						that.tab2.loading = false;
					} else {
						that.tab2.error = true
						that.tab2.loading = false;
						Toast(response.data.msg);
					}
				}, response => {
					that.tab2.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			selectPointBoxInfoList3() {
				const that = this
				let params = new FormData()
				that.axios.post(config.pointsRequest +
					`/point-api/rest/pointBoxInfo/pageList?pageNum=${that.tab3.pageNum}&pageSize=${that.tab3.pageSize}`, {
						boxType: 3
					}, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						that.tab3.pageNum = that.tab3.pageNum + 1;
						let list = that.tab3.list;
						let newList = response.data.data.rows;
						console.log(newList)
						if (newList.length < that.tab3.pageSize) that.tab3.finished = true;
						list = list.concat(newList);
						that.tab3.list = list;
						that.tab3.total = response.data.data.total
						that.tab3.loading = false;
					} else {
						that.tab3.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.tab3.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			getCkRes() {
				const that = this
				let params = new FormData()
				that.axios.post(config.pointsRequest + '/point-api/rest/pointUser/selectPointByUserId', params, {
					headers: {
						'Content-Type': 'application/json',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.points = response.data.data.points
						that.ckModal = 0;
						that.ckResModal = 1;
						Toast.clear();
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			selectPointByUserId() {
				const that = this
				let params = new FormData()
				that.axios.post(config.pointsRequest + '/point-api/rest/pointUser/selectPointByUserId', params, {
					headers: {
						'Content-Type': 'application/json',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.points = response.data.data.points
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			doCk() {
				const that = this
				let params = new FormData()
				that.axios.post(config.pointsRequest + '/point-api/rest/pointUser/selectPointByUserId', params, {
					headers: {
						'Content-Type': 'application/json',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.points = response.data.data.points
						that.ckData = {
							tokenId: '',
							points: ''
						}
						that.ckModal = 1;
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			closeCkResModal() {
				this.ckResModal = 0;
			}
		},
		activated() {
			this.selectPointByUserId();
			// this.selectPointBoxInfoList();
			this.selectPointBoxInfoList2();
			this.selectPointBoxInfoList3();
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		min-height: 100%;
		background: url(../../assets/points/bg.png);
		background-size: 750px 1755px;
	}

	.zd-wrap {
		position: absolute;
		top: 20px;
		right: 30px;
		width: 137px;
		height: 50px;
		background: #6236FF;
		border-radius: 34px;
	}

	.icon-zd {
		width: 22px;
		height: 27px;
		margin-right: 10px;
	}

	.zd-text {
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.top-wrap {
		position: relative;
		margin-top: 20px;
	}

	.my-points {
		width: 377px;
		height: 377px;
		margin: auto;
		background: url(../../assets/points/my-points.png);
		background-size: 377px;
		text-align: center;
		font-size: 50px;
		color: #000000;
		line-height: 377px;
	}

	.extraction-btn {
		width: 194px;
		height: 99px;
		position: absolute;
		left: 0;
		right: 0%;
		bottom: -35px;
		margin: auto;
	}

	.icon-ck {
		width: 114px;
		height: 64px;
		position: absolute;
		top: 0;
		right: 0;
	}

	.list-menu-wrap {
		width: 691px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 20px;
		margin: 30px auto;
	}

	.list-item {
		height: 80px;
		margin-left: 30px;
	}

	.list-item-ti {
		height: 76px;
		line-height: 76px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
	}

	.list-item-line {
		width: 50px;
		height: 4px;
		background: #AF9270;
	}

	.box-item {
		margin: 20px 30px;
		width: 691px;
	}

	.box {
		width: 335px;
		height: 670px;
		margin-bottom: 20px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	.box-img {
		width: 335px;
		height: 480px;
	}

	.ti-con {
		width: 295px;
		margin: 20px auto 17px;
	}

	.box-ti {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.box-ps {
		font-size: 28px;
		font-weight: 600;
		color: #E02020;
		flex-shrink: 0;
	}

	.box-unit {
		margin-left: 6px;
		font-size: 16px;
		font-weight: 400;
		color: #000000;
	}

	.box-desc {
		width: 295px;
		margin: auto;
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
	}

	.ck-modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
		width: 610px;
		height: 839px;
		background-image: url(../../assets/points/modal-bg.png);
		background-size: 610px 839px;
	}

	.other-login {
		display: flex;
		width: 80%;
		height: 20px;
		margin: 79px auto 0;
		background-size: cover;
		position: relative;
		justify-content: center;
		align-items: center;


	}

	.other:before {
		content: "";
		position: absolute;
		width: 157px;
		height: 2px;
		top: 50%;
		background-color: #B29574;
		left: -15px;
	}

	.other:after {
		content: "";
		position: absolute;
		width: 157px;
		height: 2px;
		top: 50%;
		background-color: #B29574;
		right: -15px;
	}

	.other-login .other .text {
		font-size: 32px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
	}

	.other-login .line {
		display: inline-block;
		width: 157px;
		border: 2px solid #B29574;
		background: #B29574;
		position: absolute;
	}

	.dh-btn-wrap {
		width: 450px;
		margin: 185px auto 0
	}

	.dh-btn-w {
		width: 210px;
		height: 74px;
		border-radius: 37px;
		border: 2px solid #B29574;
	}

	.dh-btn-t {
		width: 190px;
		height: 56px;
		text-align: center;
		line-height: 56px;
		background: #B29574;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.ck-my-points {
		width: 500px;
		height: 77px;
		margin: 30px auto 70px;
		background: rgba(98, 54, 255, 0.0600);
		border-radius: 10px;
		font-size: 30px;
		font-weight: 600;
		color: #6236FF;
		text-align: center;
		line-height: 77px;
	}

	.ck-i-wrap {
		width: 500px;
		height: 88px;
		padding: 0 30px;
		margin: 0 auto 20px;
		background: #F6F4F0;
		border-radius: 10px;
		box-sizing: border-box;
	}

	.ck-i-ti {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		flex-shrink: 0;
	}

	.ck-i-input {
		width: 280px;
		background: none;
		font-size: 30px;
		font-weight: 400;
		border: none;
	}

	.ckres-modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
		width: 550px;
		height: 840px;
		background-image: url(../../assets/points/ckres-bg.png);
		background-size: 550px 840px;
	}

	.ckres-points {
		width: 550px;
		margin-top: 274px;
		text-align: center;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
	}

	.ckres-rs-wrap {
		width: 380px;
		height: 120px;
		margin: 30px auto 123px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #B49878;
	}

	.ckres-ti {
		font-size: 26px;
		margin-top: 18px;
		font-weight: 400;
		color: #B29574;
	}

	.ckres-num {
		margin-top: 5px;
		font-size: 26px;
		font-weight: 600;
		color: #B29574;
	}

	.ckres-btn {
		width: 280px;
		height: 74px;
		margin: auto;
		border-radius: 37px;
		border: 2px solid #B29574;
	}

	.ckres-btn-text {
		width: 260px;
		height: 56px;
		background: #B29574;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 56px;
	}

	.confirm-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 38px;
	}

	.confirm-wrap {
		width: 630px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 35px 0 0;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		box-sizing: border-box;
		z-index: 1111;
	}

	.confirm-content {
		width: 296px;
		height: 77px;
		background: #FFF3EA;
		border-radius: 39px;
		text-align: center;
		line-height: 77px;
		color: #FF7731;
		font-size: 28px;
		font-weight: bold;
	}

	.btn-wrap {
		width: 100%;
		height: 88px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	.btn-left {
		width: 50%;
		height: 88px;
		text-align: center;
		line-height: 88px;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.confirm-tips {
		margin-top: 17px;
		font-size: 26px;
		font-weight: 400;
		color: #FF7731;
	}

	.confirm-tips2 {
		font-size: 26px;
		font-weight: 400;
		color: #000000;
		margin-bottom: 25px;
	}

	.btn-right {
		width: 50%;
		height: 88px;
		text-align: center;
		line-height: 88px;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.order-btn-wrap {
		position: fixed;
		right: 0;
		/* top: 0; */
		/* bottom: 0; */
		bottom: 250px;
		margin: auto;
		z-index: 10;
		width: 50px;
		height: max-content;
		padding: 11px 0;
		box-sizing: border-box;
		border-radius: 10px 0px 0px 10px;
		background: #000000;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
	}
</style>
